require("dotenv").config({
   path: `.env.${process.env.NODE_ENV}`,
});

// 1. Projects Variables
const PROJECT_NAME = "Factory Les Cygnes";
const FONTS = [`Limelight`, `Lato\:400,400i,700,700i`];
const SENTRYDSN = false;
const STORYBLOK_KEY = "IiQHBNlBPDDjnaAKdr76Ggtt";

// 2. Ajout des plugins facultatifs
let plugins = [];

// 2.1 Add Google Fonts
if (FONTS) {
   plugins.push({
      resolve: `gatsby-plugin-google-fonts`,
      options: {
         fonts: FONTS,
         display: "swap",
      },
   });
}

// 2.2 Add Sentry
if (SENTRYDSN) {
   plugins.push({
      resolve: "@sentry/gatsby",
      options: {
         dsn: SENTRYDSN,
         sampleRate: 0.7,
      },
   });
}

module.exports = {
   siteMetadata: {
      title: PROJECT_NAME,
      siteUrl: "https://www.factorylescygnes.be",
   },
   plugins: [
      {
         resolve: `gatsby-plugin-manifest`,
         options: {
            name: PROJECT_NAME,
            short_name: PROJECT_NAME,
            icon: `src/images/favicon.png`,
            start_url: `/`,
            background_color: `#db5025`,
            theme_color: `#FFF`,
            display: `standalone`,
            include_favicon: false,
         },
      },
      {
         resolve: "gatsby-source-storyblok",
         options: {
            accessToken: STORYBLOK_KEY,
            homeSlug: "/",
            version: process.env.NODE_ENV === "production" ? "published" : "draft",
            resolveRelations: ["category", "page", "block", "pages", "stories", "page.block", "reusable_block.block", "section_contact.form"],
         },
      },
      {
         resolve: `gatsby-source-filesystem`,
         options: {
            name: `images`,
            path: `${__dirname}/src/images`,
         },
      },
      "gatsby-plugin-react-helmet",
      "gatsby-transformer-sharp",
      "gatsby-plugin-postcss",
      {
         resolve: `gatsby-plugin-sitemap`,
         options: {
            output: "/sitemap.xml",
            exclude: ["/mentions-legales", "/politique-de-confidentialite", "/accueil", "/editor"],
         },
      },
      {
         resolve: "gatsby-plugin-robots-txt",
         options: {
            host: "https://www.factorylescygnes.be",
            sitemap: "https://www.factorylescygnes.be" + "/sitemap.xml",
            policy: [
               {
                  userAgent: "*",
                  allow: "/",
                  // disallow: "/",
                  disallow: ["/mentions-legales", "/politique-de-confidentialite", "/accueil", "/editor"],
               },
            ],
         },
      },
      ...plugins,
   ],
};
