import { graphql, useStaticQuery, Link } from "gatsby";
import React from "react";

const Footer = () => {
   const data = useStaticQuery(graphql`
      {
         storyblokEntry(slug: { eq: "global-navi" }) {
            id
            content
         }
      }
   `);

   const blok = JSON.parse(data.storyblokEntry.content);

   const date = new Date();
   const currentYear = date.getFullYear();

   return (
      <>
         <footer className="py-20 bg-black text-white">
            <div className="container px-4 mx-auto">
               <div className="flex flex-wrap mb-12 lg:mb-20 -mx-3 text-center lg:text-left">
                  <div className="w-full lg:w-1/5 px-3 mb-6 lg:mb-0">
                     <Link to="/" className="inline-block mx-auto lg:mx-0 text-3xl leading-none text-orange-400" dangerouslySetInnerHTML={{ __html: blok.site_informations_title }} />
                  </div>
                  <div className="w-full lg:w-2/5 px-3 mb-8 lg:mb-0">
                     <p className="max-w-md mx-auto lg:max-w-full lg:mx-0 lg:pr-32 lg:text-lg leading-relaxed">{blok.site_informations_description}</p>
                  </div>
                  <div className="w-full lg:w-1/5 px-3 mb-8 lg:mb-0">
                     <p className="mb-2 lg:mb-4 lg:text-lg font-bold">{blok.first_store_title}</p>
                     <p className="lg:text-lg" dangerouslySetInnerHTML={{ __html: blok.first_store_address?.replace("\n", "<br />") }} />
                  </div>
                  <div className="w-full lg:w-1/5 px-3">
                     <p className="mb-2 lg:mb-4 lg:text-lg font-bold">{blok.second_store_title}</p>
                     <p className="lg:text-lg" dangerouslySetInnerHTML={{ __html: blok.second_store_address?.replace("\n", "<br />") }} />
                  </div>
               </div>
               <div className="flex flex-col lg:flex-row items-center lg:justify-between">
                  <div className="flex flex-col sm:flex-row items-center text-xs text-blueGray-200 flex space-x-2">
                     <p className="mb-2 sm:mb-0">&copy; {currentYear}. Tous droits réservés.</p>{" "}
                     <ul className="flex flex-wrap justify-center space-x-2">
                        {blok.footer_nav &&
                           blok.footer_nav.map((navFooter) => {
                              return <FooterLink blok={navFooter} key={navFooter._uid} />;
                           })}
                     </ul>
                  </div>
                  <div className="order-first lg:order-last -mx-2 mb-4 lg:mb-0 flex flex-col md:flex-row items-center">
                     <p className="inline-block mb-2 md:mb-0">Rejoignez notre communauté</p>
                     <div className="mb-0 leading-none">{blok.social_links && blok.social_links.map((socialLink) => <SocialLink blok={socialLink} key={socialLink._uid} />)}</div>
                  </div>
               </div>
            </div>
         </footer>
      </>
   );
};

export default Footer;

const FooterLink = ({ blok }) => (
   <li>
      <a href={blok.link.cached_url ? `/${blok.link.cached_url}` : blok.link.url} className="focus:underline hover:underline">
         {blok.name}
      </a>
   </li>
);

const SocialLink = ({ blok }) => {
   return (
      <a href={blok.link} target="_blank" rel="noopener noreferrer" className="text-orange-400 hover:text-orange-500 inline-block px-2">
         <span className="sr-only">{blok.name}</span>
         <span dangerouslySetInnerHTML={{ __html: SocialIcon[blok.name] }} />
      </a>
   );
};

const SocialIcon = {
   Facebook: `<svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" /></svg>`,
   Twitter: `<svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24"><path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" /></svg>`,
   Instagram: `<svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" /></svg>`,
   LinkedIn: `<svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" ariaHidden="true" className="svg-inline--fa fa-linkedin fa-w-14" data-icon="linkedin" data-prefix="fab" viewBox="0 0 448 512"><path fill="currentColor" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path></svg>`,
   YouTube: `<svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" ariaHidden="true" className="svg-inline--fa fa-youtube fa-w-18" data-icon="youtube" data-prefix="fab" viewBox="0 0 576 512" > <path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" ></path> </svg>`,
   Dailymotion: `<svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" ariaHidden="true" className="svg-inline--fa fa-dailymotion fa-w-14" data-icon="dailymotion" data-prefix="fab" viewBox="0 0 448 512" > <path fill="currentColor" d="M298.93 267a48.4 48.4 0 00-24.36-6.21q-19.83 0-33.44 13.27t-13.61 33.42q0 21.16 13.28 34.6t33.43 13.44q20.5 0 34.11-13.78T322 307.47a47.13 47.13 0 00-6.1-23.47 44.13 44.13 0 00-16.97-17zM0 32v448h448V32zm374.71 373.26h-53.1v-23.89h-.67q-15.79 26.2-55.78 26.2-27.56 0-48.89-13.1a88.29 88.29 0 01-32.94-35.77q-11.6-22.68-11.59-50.89 0-27.56 11.76-50.22a89.9 89.9 0 0132.93-35.78q21.18-13.09 47.72-13.1a80.87 80.87 0 0129.74 5.21q13.28 5.21 25 17V153l55.79-12.09z" ></path> </svg>`,
   Pinterest: `<svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" ariaHidden="true" className="svg-inline--fa fa-pinterest fa-w-16" data-icon="pinterest" data-prefix="fab" viewBox="0 0 496 512" > <path fill="currentColor" d="M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z" ></path> </svg>`,
};
